<template>
  <div>
    <div v-if="success!=true" class="container">
      <img alt="Itech logo" src="../assets/logo.png" class="w-100 p-4"/>
      <h2 class="text-danger">Dosyayı yüklendikten sonra ekran üzerinden seçim yaparak çıktı alabilirsiniz</h2>

      <ImageUploader
          ref="fileInput"
          :debug="0"
          :maxWidth="1080"
          :maxHeight="1080"
          :quality="0.7"
          :autoRotate="false"
          outputFormat="blob"
          :preview="true"
          :className="['fileinput', { 'fileinput--loaded': false }]"
          accept="image/*"
          doNotResize="['gif', 'svg']"
          @input="handleFileUpload"
      >
       
        <label for="fileInput" slot="upload-label">
          <span class="upload-caption" ref="file">

          </span>
        </label>
      </ImageUploader>

      
      
      <!-- <input accept="image/png, image/gif, image/jpeg" type="file" id="file" ref="file" v-on:change="handleFileUpload($event.target.files)" hidden /> -->
        <div class="m-4">
          <b-button size="lg" class="mx-2" v-if="file==null" variant="primary" @click="$refs.file.click()">Dosya Seç</b-button>        
          <b-button size="lg" class="mx-2" v-if="file!=null" variant="success" @click="send">Gönder</b-button>
          <b-button size="lg" class="mx-2" v-if="file!=null" variant="danger" @click="remove">Sil</b-button>        
        </div>
      <b-progress v-if="value>0" height="50px" :value="value" show-progress class="mb-2"></b-progress>
      <h5 v-if="success==false" class="text-danger">Dosya yükleme başarısız!</h5>
    </div>
      
    <h3 v-if="success==true" class="text-success">Dosya Yükleme Başarılı!
      <br>
      <small class="text-warning">
         Ekran üzerinden seçim yaparak çıktı alabilirsiniz
      </small>
    </h3>
    
    <b-button class="mt-3" v-if="success==true && webShareApiSupported" variant="warning" @click="webShare">Paylaş</b-button>
    <br>   
    <router-link tag="a" class="text-white" to="/ayar" v-if="success!=true">Ayarlar</router-link>
  </div>
</template>

<script>
import axios from "axios";
import ImageUploader from "vue-image-upload-resize";

export default {
  name: 'MainComponent',
  components:{ImageUploader},
  data() {
    return {
      file:null,
      value:0,
      success:null,
      allowedExtensions: /(\.jpg|\.jpeg|\.png)$/i,
    }
  },
  computed: {
    webShareApiSupported() {
      return navigator.canShare
    }
  },
  mounted() {
    this.$refs["file"].click()
  },
  methods: {
    async webShare() {
      if (!this.file) 
        return
        
      await navigator.share({
        files: [this.file]
      });
    },
    remove(){
      this.file=null
      this.value=0
      this.success=null
    },
    /**
     * @param {FileList} files
     */
    handleFileUpload(file){
      this.file=file
    },
    send(){
      let formData=new FormData()
      formData.append("sampleFile",this.file)
      axios.post('/upload', formData, 
        {
          onUploadProgress: (progressEvent) => {
                const totalLength = progressEvent.lengthComputable ? progressEvent.total : progressEvent.target.getResponseHeader('content-length') || progressEvent.target.getResponseHeader('x-decompressed-content-length');                
                if (totalLength !== null) {
                  this.uploadPercentage = (Math.round( (progressEvent.loaded * 100) / totalLength ));
                }
          }
      })
      .then(()=>this.success=true)
      .catch(()=>this.success=false);
    },
  }
}
</script>
<style>
#fileInput {
  display: none;
}
</style>