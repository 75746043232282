import Vue from 'vue'
import VueRouter from 'vue-router'
import MainComponent from './components/Main.vue'
import SettingsComponent from './components/Settings.vue'

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: '',
    component: MainComponent
  },
  {
    path: '/ayar',
    name: 'Settings',
    component: SettingsComponent
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
