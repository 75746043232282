<template>
  <div>
    <div v-if="passed">
      <b-button @click="removePictures" variant="danger">
        Resimleri Sil
      </b-button>

      <ul>
        <li v-for="(item, index) in params" :key="index" class="m-2">
          <span>
             {{item[0]}}
          </span>
          <input type="text" v-model="item[1]">
        </li>
      </ul>
      <h3 :class="{'text-success':success==true,'text-danger':success==false}" v-if="success">{{success?'Kayıt başarılı':'Kayıt başarısız'}}</h3>

      <b-button @click="save">
        Kaydet
      </b-button>
    </div>

    <div v-else>
      Şifre <input type="password" @keyup.enter="check($event.target.value)">
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      params:[],
      passed:false,
      success:null
    }
  },
  methods: {
    check(password){
      let webpassword=this.params.find(f=>f[0]=="webpassword")[1]
      this.passed = password==webpassword
      if(!this.passed)
        alert("Şifre hatalı!")
    },
    save(){
      let data=new FormData()

      this.params.forEach((element) => {
        data.append(element[0],element[1])
      });
      
      axios.put("/config",data)
        .then(()=>{
          this.success=true
          // this.$bvToast.toast("Kayıt başarılı!",{variant:"success"})
        })
        .catch(()=>{
          this.success=false
          // this.$bvToast.toast("Kayıt başarısız!",{variant:"danger",title:'Hata!'})
        })
    },
    removePictures(){
      axios.post("/removePictures", null, { params: { password: this.params.find(f=>f[0]=="webpassword")[1] }})
        .then(()=>{
            alert("İşlem başarılı! Tüm resimler silindi")
        })
    }
  },
  
  created() {
    axios.get("/config")
      .then(res=>{
        this.params=Object.values(Object.entries(res.data))
      })
  },
}
</script>